/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import { IWebSerialDevice } from "src/components/Install/installTypes"
import { logger } from "src/utils/logger"

/**
 * @name writeToStream
 * Gets a writer from the output stream and send the lines to the micro:bit.
 * @param  {...string} lines lines to send to the micro:bit
 */
export function writeToStream(
  outputStream: WritableStream<string>,
  ...lines: string[]
) {
  try {
    const writer = outputStream.getWriter()
    lines.forEach((line: string) => {
      console.log("[SEND]", line)
      writer.write(line + "\r")
    })
    writer.releaseLock()
    return true
  } catch (e) {
    logger.error(e)
    return false
  }
}

export function writeBinaryToStream(
  outputStream: WritableStream<ArrayBuffer>,
  data: ArrayBuffer
) {
  console.log("WRITING BINARY DATA")
  console.log(data)
  try {
    const writer = outputStream.getWriter()
    writer.write(data)
    writer.releaseLock()
    return true
  } catch (e) {
    logger.error(e)
    return false
  }
}

/**
 * @name LineBreakTransformer
 * TransformStream to parse the stream into lines.
 */
export class LineBreakTransformer {
  container = ""
  constructor() {
    // A container for holding stream data until a new line.
    this.container = ""
  }

  transform(chunk: any, controller: any) {
    // CODELAB: Handle incoming chunk
    this.container += chunk
    const lines = this.container.split("\n")
    this.container = lines.pop() || ""
    lines.forEach((line) => controller.enqueue(line))
  }

  flush(controller: any) {
    // CODELAB: Flush the stream.
    controller.enqueue(this.container)
  }
}

const MINUT_USB_VENDOR_ID = 0x483
/**
 * @name connect
 * Opens a Web Serial connection to a micro:bit and sets up the input and
 * output stream.
 */
export async function webSerialConnect(
  usbVendorId = MINUT_USB_VENDOR_ID
): Promise<IWebSerialDevice> {
  const serial = navigator.serial
  // - Request a port and open a connection.
  const requestOptions = { filters: [{ usbVendorId }] }
  logger.log("Requesting port", requestOptions)
  const port = await serial.requestPort(requestOptions)
  // - Wait for the port to open.
  logger.log("Waiting for port to open...")
  try {
    await port.open({ baudRate: 9600 })
  } catch (e: any) {
    logger.error(e, e.type)
    throw e
  }
  logger.log("Port open!")

  // CODELAB: Add code setup the output stream here.
  const encoder = new TextEncoderStream()
  const outputDone = encoder.readable.pipeTo(port.writable)
  const outputStream = encoder.writable

  // // CODELAB: Send CTRL-C and turn off echo on REPL
  // // writeToStream("\x03", "echo(false);");

  // CODELAB: Add code to read the stream here.
  const decoder = new TextDecoderStream()
  const inputDone = port.readable.pipeTo(decoder.writable)
  const inputStream = decoder.readable.pipeThrough(
    new TransformStream(new LineBreakTransformer())
  )
  const reader = inputStream.getReader()

  console.log("Connect done")
  return { port, outputDone, outputStream, inputDone, inputStream, reader }
}

/**
 * @name disconnect
 * Closes the Web Serial connection.
 */
export async function webSerialDisconnect({
  port,
  reader,
  outputStream,
  outputDone,
  inputDone,
}: IWebSerialDevice) {
  // XXX: Are setting vars to null really needed??
  if (reader) {
    await reader.cancel()
    await inputDone?.catch(() => {})
    reader = null
    inputDone = null
  }

  if (outputStream) {
    await outputStream.getWriter().close()
    await outputDone
    outputStream = null
    // outputDone = null
  }

  await port.close()
  console.log("Port closed")
  // port = null
  console.log("Disconnected")
  return port
}

export function webSerialSend(
  cmd: string,
  outputStream: WritableStream<string>
) {
  writeToStream(outputStream, cmd)
  return true
}
